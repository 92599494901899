(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/icon-with-text/views/index.js') >= 0) return;  svs.modules.push('/widgets/icon-with-text/views/index.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.widget=_svs.widget||{};
_svs.widget.icon_with_text=_svs.widget.icon_with_text||{};
_svs.widget.icon_with_text.templates=_svs.widget.icon_with_text.templates||{};
svs.widget.icon_with_text.templates.index = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-impression=\"true\" data-impression-tracking=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":54},"end":{"line":2,"column":63}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"trackingId") || (depth0 != null ? lookupProperty(depth0,"trackingId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trackingId","hash":{},"data":data,"loc":{"start":{"line":2,"column":64},"end":{"line":2,"column":78}}}) : helper)))
    + "\"\n  class=\"widget-default"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFullWidth") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":3,"column":23},"end":{"line":3,"column":135}}})) != null ? stack1 : "")
    + "\n  align-center padding-h-2 padding-xs-bottom-3 padding-am-bottom-2 f-body gutter-sm-1 gutter-am-2\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"invertText") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":5,"column":38}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"background") || (depth0 != null ? lookupProperty(depth0,"background") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"background","hash":{},"data":data,"loc":{"start":{"line":5,"column":39},"end":{"line":5,"column":53}}}) : helper)))
    + " icon-with-text-line-height\">\n    <div"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFullWidth") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":77}}})) != null ? stack1 : "")
    + ">\n        <h2 class=\"h2 padding-bottom-2 padding-xs-top-2 padding-am-top-3\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"header") || (depth0 != null ? lookupProperty(depth0,"header") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"header","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":22}}}) : helper)))
    + "\n        </h2>\n    <div class=\"grid-row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"columnDisplay") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":75,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"buttonText") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":8},"end":{"line":81,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " widget-full-width widget-full-height";
},"4":function(container,depth0,helpers,partials,data) {
    return " u-border-radius-12 icon-with-text-center";
},"6":function(container,depth0,helpers,partials,data) {
    return " f-inverted";
},"8":function(container,depth0,helpers,partials,data) {
    return " class=\"padding-2 widget-full-width-center\"";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"u-flex u-flex-column u-flex-align-items-center\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"instructionList") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":40,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"col-xs-12 col-sm-12 col-am-8 col-md-8 col-lg-6 col-xl-6 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":14,"column":88},"end":{"line":14,"column":154}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"iconImage") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.program(21, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":38,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "padding-am-bottom-0";
},"14":function(container,depth0,helpers,partials,data) {
    return "padding-am-bottom-2";
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"center-image max-width-image\">\n                                <div class=\"margin-xs-bottom-1 margin-am-bottom-2\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"invertText") : depths[1]),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":18,"column":36},"end":{"line":22,"column":43}}})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n                            <h3 class=\"h3 margin-bottom\">\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"taskHeading") || (depth0 != null ? lookupProperty(depth0,"taskHeading") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"taskHeading","hash":{},"data":data,"loc":{"start":{"line":26,"column":32},"end":{"line":26,"column":47}}}) : helper)))
    + "\n                            </h3>\n                            <div class=\"margin-bottom-4\">\n                                <p class=\"align-left\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"taskDescription") || (depth0 != null ? lookupProperty(depth0,"taskDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"taskDescription","hash":{},"data":data,"loc":{"start":{"line":29,"column":54},"end":{"line":29,"column":73}}}) : helper)))
    + "</p>\n                            </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"iconImage") : depth0),{"name":"components-content-image_v2-image","hash":{"cssSelectors":"invert-image"},"data":data,"indent":"                                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"iconImage") : depth0),{"name":"components-content-image_v2-image","data":data,"indent":"                                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <h3 class=\"h3 margin-bottom\">\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"taskHeading") || (depth0 != null ? lookupProperty(depth0,"taskHeading") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"taskHeading","hash":{},"data":data,"loc":{"start":{"line":33,"column":32},"end":{"line":33,"column":47}}}) : helper)))
    + "\n                            </h3>\n                            <div class=\"margin-bottom-4 padding-bottom-4\">\n                                <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"taskDescription") || (depth0 != null ? lookupProperty(depth0,"taskDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"taskDescription","hash":{},"data":data,"loc":{"start":{"line":36,"column":35},"end":{"line":36,"column":54}}}) : helper)))
    + "</p>\n                            </div>\n";
},"23":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"u-flex u-flex-justify-content-center u-flex-wrap\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"instructionList") : depth0),{"name":"each","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":16},"end":{"line":72,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n";
},"24":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"col-xs-12 col-sm-12 col-am-6 col-md-4 col-lg-4 col-xl-4 padding-am-bottom-2\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"iconImage") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.program(21, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":47,"column":24},"end":{"line":70,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n";
},"25":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"center-image max-width-image\">\n                                <div class=\"margin-xs-bottom-1 margin-am-bottom-2\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"invertText") : depths[1]),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":50,"column":36},"end":{"line":54,"column":43}}})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n                            <h3 class=\"h3 margin-bottom\">\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"taskHeading") || (depth0 != null ? lookupProperty(depth0,"taskHeading") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"taskHeading","hash":{},"data":data,"loc":{"start":{"line":58,"column":32},"end":{"line":58,"column":47}}}) : helper)))
    + "\n                            </h3>\n                            <div class=\"margin-bottom-4\">\n                                <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"taskDescription") || (depth0 != null ? lookupProperty(depth0,"taskDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"taskDescription","hash":{},"data":data,"loc":{"start":{"line":61,"column":35},"end":{"line":61,"column":54}}}) : helper)))
    + "</p>\n                            </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"padding-am-bottom-2\">\n                "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"size":300,"data-click-tracking":(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),"/",(depth0 != null ? lookupProperty(depth0,"trackingId") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":79,"column":42},"end":{"line":79,"column":71}}}),"inverted":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"buttonType") : stack1),"href":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"buttonLink") : stack1)},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":16},"end":{"line":79,"column":118}}})) != null ? stack1 : "")
    + "\n            </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? lookupProperty(stack1,"content") : stack1)) != null ? lookupProperty(stack1,"buttonText") : stack1), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currentWidget") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":84,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['widget-icon_with_text-index'] = svs.widget.icon_with_text.templates.index;
})(svs, Handlebars);


 })(window);